import React from 'react'
import sanitizeHtml from 'sanitize-html'

export const allowance = {
    allowedTags: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'blockquote',
        'p',
        'a',
        'ul',
        'ol',
        'nl',
        'li',
        'b',
        'i',
        'strong',
        'em',
        'strike',
        'code',
        'hr',
        'br',
        'div',
        'table',
        'thead',
        'caption',
        'tbody',
        'tr',
        'th',
        'td',
        'pre',
        'iframe',
    ],
    allowedAttributes: {
        a: ['href', 'name', 'target'],
        // We don't currently allow img itself by default, but this
        // would make sense if we did. You could add srcset here,
        // and if you do the URL is checked for safety
        img: ['src'],
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: [
        'img',
        'br',
        'hr',
        'area',
        'base',
        'basefont',
        'input',
        'link',
        'meta',
    ],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowProtocolRelative: true,
}

export const injectSanitiseHtml = (
    htmlFragment = '<div></div>',
    tagType = 'div',
    styles = {}
) => {
    if (tagType === 'h1') {
        return (
            <h1
                style={styles}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(htmlFragment, allowance),
                }}
            ></h1>
        )
    } else if (tagType === 'h2') {
        return (
            <h2
                style={styles}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(htmlFragment, allowance),
                }}
            ></h2>
        )
    } else if (tagType === 'h3') {
        return (
            <h3
                style={styles}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(htmlFragment, allowance),
                }}
            ></h3>
        )
    } else if (tagType === 'h4') {
        return (
            <h4
                style={styles}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(htmlFragment, allowance),
                }}
            ></h4>
        )
    } else if (tagType === 'p') {
        return (
            <p
                style={styles}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(htmlFragment, allowance),
                }}
            ></p>
        )
    } else if (tagType === 'div') {
        return (
            <div
                style={styles}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(htmlFragment, allowance),
                }}
            ></div>
        )
    } else {
        return (
            <div
                style={styles}
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(htmlFragment, allowance),
                }}
            ></div>
        )
    }
}

export const isMobileSize = width => {
    if (width < 510) {
        return true
    }
    return false
}

export const isTabletSize = width => {
    if (width < 1100) {
        return true
    }
    return false
}